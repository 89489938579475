import React, { useState, useEffect, useCallback } from "react";
import "../../styling/App.css";
import { forwardRef } from "react";
import Avatar from "react-avatar";

import { CsvBuilder } from "filefy";
import { useWindowResize } from "../FrontEnd_Table/useWindowsResize";
 
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import PoliceReport from "@material-ui/icons/Gavel";

import { TextField } from "@material-ui/core";

import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { trackPromise } from "react-promise-tracker";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
//api that the data is manipulated from
const api = axios.create({
    baseURL: `https://9ok67szb42.execute-api.us-east-1.amazonaws.com/prod`,
});



const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50",
        },
        secondary: {
            main: "#1499EF",
        },
    },
});
//Validation logic for both urls, and phone numbers
function validateURL(url) {
    var pattern = new RegExp(
        // eslint-disable-next-line
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    );
    var result = pattern.test(url);
    if (result === true) {
        console.log("Valid url");
        return true;
    } else {
        console.log("Invalid url");
        return false;
    }
}

function validatePhoneNumber(phoneNum) {
    var pattern = new RegExp(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g
    );
    if (!pattern.test(phoneNum)) {
        console.log(
            "Result from " + phoneNum + " is " + pattern.test(phoneNum)
        );
        console.log("Invalid phone number");
        return false;
    } else {
        console.log("Valid phone number accepted.");
        return true;
    }
}

function downloadPDF(urlIn,reportName)
{
    console.log(urlIn)
    var url = urlIn.replace(/^"(.+(?="$))"$/, '$1');
    var anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', reportName);

    /*
     * Click the anchor
     */
    
    // Chrome can do anchor.click(), but let's do something that Firefox can handle too
    
    // Create event
    var ev = document.createEvent("MouseEvents");
    ev.initMouseEvent("click", true, false, window.self, 0, 0, 0, 0, 0, false, false, false, false, 0, null);

    // Fire event
    try{
        anchor.dispatchEvent(ev);
    }
    catch{
        alert("file doesn't exist");
    }
    }


function shoot(urlIn) {
    var str = urlIn;
    var n = str.lastIndexOf('/');
    var reportName = str.substring(n + 1);
    var url_passin = "/report?" + urlIn
        console.log(url_passin)
         api.get(url_passin)
             .then((r) => {
                 console.log(JSON.stringify(r.data.reportURL));
                    downloadPDF(JSON.stringify(r.data.reportURL),reportName);
             })      

}

function DisplayTable(userInfo) {
    var COLUMNS = [
        
        { title: "Case Number *", field: "CaseNumber", editable: "onAdd" },
        { title: "Added By", field: "addedBy", editable: "never" },
        { title: "Continuous Run", field: "ContinuousRun" },
        { title: "Deconfliction", field: "Deconfliction" },
        { title: "Reason *", field: "reason" },
        { title: "Report Delivered", field: "ReportDelivered" },
        { title: "View Report", 
            field: "",
                render: (rowData) => (
                    <div>
                        {rowData.ReportDelivered=='Yes' ? <button onClick={() =>shoot("name=utah-co-ut-so/" + rowData.CaseNumber + "/" + rowData.CaseNumber + "-CyberCheck-report.pdf")}>PDF</button> : ''}
                    </div>
            ),
        },
        { title: "Specific Request", field: "SpecificRequest" },
        { title: "Name", field: "name_in" },
        { title: "Date of Birth", field: "dob"},
        { title: "Address", field: "address" },
        { title: "City", field: "city" },
        { title: "Country", field: "country" },
        { title: "Phone Number", field: "phone" },
        { title: "Email Address", field: "email" },
        { title: "Online Alias", field: "alias" },
        { title: "IP Address", field: "ip" },
       
        //{ title: "Date Added", field: "date", editable: "never", visable: "false" },
        {
            title: "URL",
            field: "url_in",
            render: (rowData) => (
                <a
                    href={rowData.url_in}
                    // eslint-disable-next-line
                    target="_blank"
                    style={{ wordBreak: "break-all" }}>
                    {rowData.url_in}
                </a>
            ),
        }
        ];

    //table data
    const [data, setData] = useState([]);
    //checks if its updated, removing the need to have the page refresh.
    const [isUpdated, setIsUpdated] = useState(false);
    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    //Being resized based on the users window size
    const { height } = useWindowResize();

    const fetchData = useCallback(() => {
        api.get("/{proxy+}/",
                     {headers: { 'Authorization': userInfo.userInfo
                    }})
            .then((res) => {
                setData(res.data);
                console.log(res.data);
                setIsUpdated(false);
            })
            .catch((error) => {
                console.log("Error");
                console.log(error.data);
            });
        // eslint-disable-next-line
    }, [isUpdated]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleRowAdd = (newData, resolve) => {
        //validation of new row data
        let errorList = [];
        console.log(newData.url_in);
       /* if (
            newData.url_in === undefined ||
            validateURL(newData.url_in) === false
        ) {
            errorList.push("Please enter a valid url.");
        }*/
        if (newData.CaseNumber === undefined)
            {
                errorList.push("Case Number is a mandatory field");
            }
        
        if (newData.reason === undefined)
            {
                errorList.push("Reason is a mandatory field");
            }

        if (errorList.length < 1) {
            //no error
            api.post("/{proxy+}/", newData,
                     {headers: { 'Authorization': userInfo.userInfo
                    }})
                .then((res) => {
                    setIsUpdated(true);
                    let dataToAdd = [...data];
                    dataToAdd.push(newData);
                    setData(dataToAdd);
                    resolve();
                    setErrorMessages([]);
                    setIserror(false);
                    window.alert("Your case has been submitted for processing.");
                    // window.location.reload(true);
                })
                .catch((error) => {
                    setErrorMessages(["Cannot add data. Server error!"]);
                    setIserror(true);
                    resolve();
                });
        } else {
            setErrorMessages(errorList); 
            setIserror(true);
            resolve();
        }
    };

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        console.log("Phone number: " + newData.phone);
        let errorList = [];
        
        if (newData.reason === undefined)
            {
                errorList.push("Reason is a mandatory field");
            }
        /*if (newData.url_in === undefined) {
            errorList.push("Please enter URL");
        }
        if (
            newData.url_in === undefined ||
            validateURL(newData.url_in) === false
        ) {
            errorList.push("Please enter a valid URL.");
        }
        if (
            newData.phone === undefined ||
            validatePhoneNumber(newData.phone) === false
        ) {
            console.log("Phone number invalid: " + newData.phone);
            errorList.push("Please enter a valid phone number.");
        }
        */
        if (errorList.length < 1) {
            api.patch("/{proxy+}/" + newData.CaseNumber, newData,
                     {headers: { 'Authorization': userInfo.userInfo
                    }})
                .then((res) => {
                    setIsUpdated(true);
                    const dataUpdate = [...data];
                    const index = oldData.tableData.CaseNumber;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    resolve();
                    setIserror(false);
                    setErrorMessages([]);
                    // window.location.reload(true);
                })
                .catch((error) => {
                    setErrorMessages(["Update failed! Server error"]);
                    setIserror(true);
                    resolve();
                });
        } else {
            setErrorMessages(errorList);
            setIserror(true);
            resolve();
        }
    };

    const handleRowDelete = (oldData, resolve) => {
        var CaseNumber = encodeURIComponent(oldData.CaseNumber);
        var w = CaseNumber.toString();
        console.log("The url is: " + w);
        api.delete("/list/" + w,
                     {headers: { 'Authorization': userInfo.userInfo
                    }})
            .then((res) => {
                setIsUpdated(true);
                const dataDelete = [...data];
                const index = oldData.CaseNumber;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve();
                setIserror(false);
                setErrorMessages([]);
                // window.location.reload(true);
            })
            .catch((error) => {
                console.log("This is the key value " + oldData.CaseNumber);
                setErrorMessages(["To delete a record, please contact support@cybercheck.ai"]);
                setIserror(true);
                resolve();
            });
    };

    return (
        <div className="DisplayTable" style={{ marginTop: "5vh" }}>
            <Grid container spacing={1}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <div>
                        {iserror && (
                            <Alert severity="error">
                                {errorMessages.map((msg, i) => {
                                    return <div key={i}>{msg}</div>;
                                })}
                            </Alert>
                        )}
                    </div>
                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            title="CyberCheck"
                            columns={COLUMNS}
                            data={data}
                            icons={tableIcons}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        handleRowUpdate(
                                            newData,
                                            oldData,
                                            resolve
                                        );
                                    }),
                                onRowAdd: (newData) =>
                                    new Promise((resolve) => {
                                        handleRowAdd(newData, resolve);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        handleRowDelete(oldData, resolve);
                                    }),
                            }}
                            options={{
                                selection: true,
                                exportButton: false,
                                exportAllData: false,
                                emptyRowsWhenPaging: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 25, 50, 100, 200],
                                headerStyle: { position: "sticky", top: 0 },
                                maxBodyHeight: height - 300,
                                overflowX: "hidden",
                                overflowY: "auto",
                            }}
                            actions={[
                                {
                                    position: "toolbarOnSelect",
                                    icon: SaveAlt,
                                    tooltip: "Export the selected rows!",
                                    onClick: (e, rowData) => {
                                        const fileName = "utah-co-ut-so_Production_Data_Storage";
                                        const builder = new CsvBuilder(
                                            fileName + ".csv"
                                        );
                                        builder
                                            .setColumns(
                                                COLUMNS.slice(0).map(
                                                    (columnDef) =>
                                                        columnDef.title
                                                )
                                            )
                                            .addRows(
                                                rowData.map((rowData) =>
                                                    COLUMNS.slice(0).map(
                                                        (columnDef) =>
                                                            rowData[
                                                                columnDef.field
                                                            ]
                                                    )
                                                )
                                            )
                                            .exportFile();
                                    },
                                },/*
                                {
                                    position: "toolbarOnSelect",
                                    icon: PoliceReport,
                                    tooltip:
                                        "Select rows to create police report.",
                                    onClick: (columns, data) => {
                                        alert(
                                            "TODO: develop code to generate report that contains " +
                                                data.length +
                                                " rows of formatted data"
                                        );
                                    },
                                },
                                {
                                    position: "toolbarOnSelect",
                                    icon: DeleteOutline,
                                    tooltip: "Delete the selected rows!",
                                    onClick: (e, rowData) => {
                                        rowData.forEach((rd) => {
                                            trackPromise(
                                                new Promise((resolve) => {
                                                    handleRowDelete(
                                                        rd,
                                                        resolve
                                                    );
                                                })
                                            );
                                        });
                                    },
                                },*/
                            ]}
                        />
                    </MuiThemeProvider>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    );
}


export default DisplayTable;